<template>
  <div class="divBox">
    <div class="selCard">
      <el-form
        :model="tableFrom"
        ref="searchForm"
        inline
        size="small"
        label-width="90px"
        @submit.native.prevent
      >
        <el-form-item label="标题:" prop="title">
          <el-input
            v-model="tableFrom.title"
            placeholder="请输入标题"
            class="selWidth"
          />
        </el-form-item>
        <el-form-item label="联系人姓名:" prop="contacts">
          <el-input
            v-model="tableFrom.contacts"
            placeholder="请输入联系人姓名"
            class="selWidth"
          />
        </el-form-item>
        <el-form-item label="联系人电话:" prop="contactsPhone">
          <el-input
            v-model="tableFrom.contactsPhone"
            placeholder="请输入联系人电话"
            class="selWidth"
          />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="search()"
            >搜索</el-button
          >
          <el-button size="small" @click="searchReset()">重置</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-card class="mt14">
      <el-table v-loading="listLoading" :data="tableData.data" size="small">
         <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100"
          label="序号"
        />
        <el-table-column prop="title" label="标题" min-width="60" />
        <el-table-column prop="contacts" label="联系人" min-width="60" />
        <el-table-column
          prop="contactsPhone"
          label="联系人电话"
          min-width="60"
        />
        <el-table-column label="描述图片" min-width="80">
          <template slot-scope="scope">
            <div v-if="scope.row.imgUrl" style="display: flex">
              <div
                class="demo-image__preview"
                v-for="(item, index) in scope.row.imgUrl.split(',')"
                style="margin-right: 10px"
                :key="index"
              >
                <el-image
                  style="width: 36px; height: 36px"
                  :src="item"
                  :preview-src-list="[item]"
                />
              </div>
            </div>
            <div v-else>暂无图片</div>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          min-width="50"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <!--<el-button type="text" size="small" @click="onEdit(scope.row.feedback_id)">备注</el-button>-->
            <el-button type="text"  icon="el-icon-info" size="small" @click="onDetails(scope.row)"
              >详情</el-button
            >
            <!-- <el-button
                v-if="scope.row.status != 1"
                type="text"
                size="small"
                @click="handleReply(scope.row.feedback_id)"
              >回复</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>

    <!--反馈详情-->
    <FeedbackDetails
      @closeDrawer="closeDrawer"
      :drawer="drawer"
      :info="info"
      ref="FeedbackDetails"
    />
  </div>
</template>
  
  <script>
import { userFeedbackList, userFeedbackDetial } from "@/api/user/list";
import FeedbackDetails from "./feedbackDetails.vue";
import { getIndex } from "@/utils/other";
export default {
  name: "Classify",
  components: {
    FeedbackDetails,
  },
  data() {
    return {
      tableData: {
        data: [],
        total: 0,
      },
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
      },
      listLoading: true,
      drawer: false,
      info: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getTabelIndex(index) {
      return getIndex(index, this.tableFrom.pageNum, this.tableFrom.pageSize);
    },
    /**重置 */
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.getList();
    },
    closeDrawer() {
      this.drawer = false;
    },
    search() {
      this.tableFrom.pageNum = 1;
      this.getList();
    },
    // 列表
    getList() {
      this.listLoading = true;
      userFeedbackList(this.tableFrom)
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
          this.$message.error(res.message);
        });
    },
    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    // 回复
    handleReply(id) {
      this.$modalForm(replyFeedbackApi(id)).then(() => this.getList(""));
    },
    // 编辑
    onEdit(id) {
      this.$prompt("备注内容", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputErrorMessage: "请输入备注内容",
        inputType: "textarea",
        inputPlaceholder: "请输入回复内容",
        inputValidator: (value) => {
          if (!value) {
            return "输入不能为空";
          }
        },
      })
        .then(({ value }) => {
          feedbackReplyApi(id).then((res) => {
            this.$message({
              type: "success",
              message: "备注成功",
            });
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    // 详情
    onDetails(row) {
      userFeedbackDetial(row.id).then((res) => {
        this.info = res.data;
        this.drawer = true;
      });
    },
  },
};
</script>
  
  <style scoped lang="scss">
</style>
  